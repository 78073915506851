import { colors } from "@/styles/theme";
import cookieCutter from "@boiseitguru/cookie-cutter";
import { ConfigProvider, notification } from "antd";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import Router from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { HashLoader } from "react-spinners";
import { wrapper } from "store";
import "styles/global.css";

const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps<{ session: Session }>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [posthogKey, setPosthogKey] = useState<string | undefined>(undefined);

  const { store, props } = wrapper.useWrappedStore(pageProps);

  notification.config({
    placement: "bottomRight",
  });

  useEffect(() => {
    const fetchPosthogKey = async () => {
      const response = await fetch("/api/posthog/key");
      const data = await response.json();
      setPosthogKey(data.posthogKey);
    };

    fetchPosthogKey();
  }, []);

  useEffect(() => {
    if (!posthogKey) return;

    const bootstrapData = cookieCutter.get("bootstrapData");

    let bootstrap = {};
    if (bootstrapData) {
      bootstrap = JSON.parse(bootstrapData);
    }

    posthog.init(posthogKey, {
      api_host: "/ingest",
      ui_host: "https://us.i.posthog.com",
      person_profiles: "identified_only",
      autocapture: false,
      bootstrap,
      // Enable debug mode in development
      loaded: posthog => {
        if (process.env.NODE_ENV === "development") posthog.debug();
      },
    });
  }, [posthogKey]);

  useEffect(() => {
    Router.events.on("routeChangeStart", () => {
      setIsLoading(true);
    });

    Router.events.on("routeChangeComplete", () => {
      setIsLoading(false);
    });

    Router.events.on("routeChangeError", () => {
      setIsLoading(false);
    });

    /**
     * This block enables us to manipulate and assert on
     * the Redux store from within Cypress tests.
     * DO NOT REMOVE
     */
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.store = store;
    }

    return () => {
      Router.events.off("routeChangeStart", () => {
        setIsLoading(false);
      });

      Router.events.off("routeChangeComplete", () => {
        setIsLoading(false);
      });

      Router.events.off("routeChangeError", () => {
        setIsLoading(false);
      });
    };
  }, []);

  useEffect(() => {
    // Disable scrolling when loading.
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primaryBlue,
        },
      }}
    >
      <SessionProvider session={session}>
        <PostHogProvider client={posthog}>
          <Provider store={store}>
            {isLoading ? (
              <div className="router-loading-wrapper">
                <HashLoader color={colors.primaryBlue} size={80} />
              </div>
            ) : null}
            <Component {...props} />
          </Provider>
        </PostHogProvider>
      </SessionProvider>
    </ConfigProvider>
  );
};

export default App;
